(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
  
  'use strict';

// великий и ужасный jq
// window.$ = window.jQuery = require('jquery');  


// Слайдер slick
// require('slick-carousel'); 


// require('@fancyapps/fancybox');  


// буллет-навигация
// require('jquery-scroll-indicator-bullets');

// маски для полей на чистом js
// var VMasker = require('vanilla-masker'); window.VMasker = VMasker;

// адаптивный lazyload для изображений https://www.npmjs.com/package/lazysizes
// window.lazysizes = require('lazysizes');

// адаптивный lazyload фон для изображений
// var lazyBgrAdaptive = require('./components/lazyBgrAdaptive.js'); lazyBgrAdaptive();

// полифил для свойства object-fit у изображений
// var objectFitImages = require('object-fit-images'); objectFitImages();

// первый экран — splashScreen
// var splashScreen = require('./components/splashScreen.js'); splashScreen();

// компонент для меню components/menuList.html
// var menuList = require('./components/menuList.js'); menuList();

// выезжающие панели 
// var swipePanel = require('./components/swipePanel.js'); swipePanel();

// карточка, показывающая содержимое по клику на заголовок /components/collapseCard.html
// var collapseCard = require('./components/collapseCard.js'); collapseCard();


// Инициализация роллера
// var calcRollerInit = require('./components/calcRollerInit.js');

// табы
var tabs = require('./components/tabs.js'); tabs();

// Селекты красивые
// var dropdown = require('./components/disDropdown.js'); dropdown();

// Селекты красивые
// var CheckboxToSelect = require('./components/CheckboxToSelect.js'); CheckboxToSelect();



// window.lightSlider = require('lightslider');
// window.remodal = require('remodal');

},{"./components/tabs.js":2}],2:[function(require,module,exports){
'use strict';

// var $ = require('jquery');

module.exports = function () {
  $(document).delegate('.js__tabs [data-button]', 'click', function(e){
    e.preventDefault();
    var $cotainer = $(this).closest('.js__tabs');
    var index = $(this).data('button');

    $('[data-button]', $cotainer).removeClass('is-active');
    $('[data-button=' + index + ']', $cotainer).addClass('is-active');
    $('[data-tab]', $cotainer).removeClass('is-active');
    $('[data-tab=' + index + ']', $cotainer).addClass('is-active');
  });
};

},{}]},{},[1]);
